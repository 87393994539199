<template>
	<v-col lg="4" class="auth-bg pa-10 pb-0 d-flex align-center">
		<v-col cols="12" sm="8" md="6" lg="12" class="mx-auto">
			<v-card flat>
				<v-card-text>
					<h2 class="primary--text mb-2">S'inscrire</h2>
					<p class="mb-2">inscrivez vous pour acceder a votre dashboard</p>
				</v-card-text>

				<v-card-text>
					<v-form @submit.prevent="register">

						<v-text-field v-model="email" outlined label="Email" placeholder="email" hide-details class="mb-6" required />
						<v-text-field v-model="passwordOne" outlined :type="isPasswordOneVisible ? 'text' : 'password'"
							label="Password" placeholder="Password" class="mb-2" required
							:append-icon="isPasswordOneVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'" hide-details="auto"
							@click:append="isPasswordOneVisible = !isPasswordOneVisible"
						/>
						<v-text-field v-model="passwordTwo" outlined :type="isPasswordTwoVisible ? 'text' : 'password'"
							label="Password" placeholder="Password" class="mb-2" required
							:append-icon="isPasswordTwoVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'" hide-details="auto"
							@click:append="isPasswordTwoVisible = !isPasswordTwoVisible"
						/>

						<v-checkbox v-model="accepted" :label="checkboxLabel" color="primary" hide-details class="py-5" />
						<v-btn block color="primary" type="submit" class="mt-6" :disabled="notValid">S'inscrire</v-btn>

					</v-form>
					<p class="text-center ma-5"><a href="#" @click="changeMode">Se connecter</a></p>
				</v-card-text>
			</v-card>
		</v-col>
	</v-col>
</template>

<script>
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import Profile from '@/classes/Profile'

export default {
	data() {
		return {
			auth: getAuth(),

			checkboxLabel: `J'accepte que mon adresse e-mail ainsi que mon nom et prénom soit collectés dans le but d'utiliser les services de Valy. Elle ne pourra être utilisée à des fins commerciales et/ou en dehors du cadre de l'utilisation desdits services.`,
			isPasswordOneVisible: false,
			isPasswordTwoVisible: false,

			accepted: false,
			email: '',
			passwordOne: '',
			passwordTwo: ''
		}
	},
	computed: {
		notValid() {
			return this.email.length <= 0 || this.passwordOne.length <= 0 || this.passwordTwo.length <= 0 || this.passwordOne !== this.passwordTwo || !this.accepted
		},
	},
	methods: {
		changeMode() {
			this.$emit('changeMode')
		},
		async register() {
			const response = await Profile.getByMail(this.email)
			if (response) {
				Swal.fire({
					title: 'Erreur',
					text: 'Cet email est déjà utilisé.',
					icon: 'error',
					confirmButtonText: 'Ok'
				})
			} else {
				createUserWithEmailAndPassword(this.auth, this.email, this.passwordOne)
					.then(() => {
						this.auth.signOut()
						Swal.fire({
							title: 'Inscription réussie',
							text: 'Vous pouvez maintenant vous connecter',
							icon: 'success',
							confirmButtonText: 'OK'
						}).then(() => {
							this.$emit("changeMode")
						})
					})
					.catch(error => {
						Swal.fire({
							title: 'Erreur',
							text: error.message,
							icon: 'error',
							confirmButtonText: 'Ok'
						})
					})
			}
		}
	}
}
</script>
