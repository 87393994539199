<template>
	<v-col lg="4" class="d-flex align-center auth-bg pa-10 pb-0">
		<v-row>
			<v-col cols="12" sm="8" md="6" lg="12" class="mx-auto">
				<v-card flat>
					<v-card-text>
						<p class="text-2xl font-weight-semibold text--primary mb-2">Bienvenue ! 👋🏻</p>
						<p class="mb-2">Connectez vous pour acceder a votre dashboard</p>
					</v-card-text>

					<!-- login form -->
					<v-card-text>
						<v-form @submit.prevent>
							<v-text-field v-model="email" type="email"
                                outlined label="Email" placeholder="email" hide-details="auto" class="mb-6"
                            />

							<v-text-field v-model="password" :type="isPasswordVisible ? 'text' : 'password'"
                                outlined label="Password" placeholder="Password" hide-details="auto" class="mb-2"
                                :append-icon="isPasswordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                                @click:append="isPasswordVisible = !isPasswordVisible"
                            />

							<v-btn block color="primary" type="submit" class="mt-6" @click="login"> Se connecter </v-btn>
                            <p class="text-center ma-0 my-3">ou</p>
							<v-btn block color="error" type="submit" @click="loginWithGoogle"> Se connecter avec Google </v-btn>
                            <p class="text-center ma-0 my-3">ou</p>

                            <p class="text-center"><a href="#" @click.prevent.stop="changeMode">Je n'ai pas encore de compte</a> / <a href="#" @click.prevent.stop="forgotModalDisplayed = true">Mot de passe oublié</a></p>

						</v-form>
					</v-card-text>

                    <v-dialog v-model="forgotModalDisplayed" max-width="600px" persistent>
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">Entrez votre adresse e-mail</span>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field  label="Email" v-model="forgotEmail" required />
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="forgotEmail = '' ; forgotModalDisplayed = false"> Annuler </v-btn>
                                <v-btn color="blue darken-1" text @click="SendResetEmail(forgotEmail)"> envoyer </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
				</v-card>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
	import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail, signInWithPopup, GoogleAuthProvider } from "firebase/auth"

    import Swal from 'sweetalert2/dist/sweetalert2.js'

	export default {
        name: "LoginForm",
        data() {
            return {
                auth: getAuth(),
				isPasswordVisible: false,
				email: '',
				password: '',
                forgotModalDisplayed: false,
                forgotEmail: "",
                googleProvider : new GoogleAuthProvider()
            }
        },
        created() {
            this.googleProvider.setCustomParameters({
                prompt: "select_account"
            })
        },
		methods: {
			login() {
				signInWithEmailAndPassword(getAuth(), this.email, this.password)
					.then((userCredential) => {
						this.$router.push("/home")
					})
					.catch((error) => {
						this.email = ""
						this.password = ""
					})
			},
			loginWithGoogle() {
				signInWithPopup (getAuth(), this.googleProvider)
                .then((userCredential) => {
                    this.$router.push("/home")
                })
			},
			changeMode(){
                this.$emit("changeMode")
            },
            SendResetEmail(email){
                sendPasswordResetEmail(this.auth, email, {url: window.location.origin})
                .then( () => {
                    this.forgotModalDisplayed = false
                    Swal.fire({
                        title: "MAIL ENVOYÉ",
                        text: "Un e-mail a été envoyé pour réinitialiser le mot de passe (pensez à vérifier le dossier spam).",
                        icon: "success",
                        confirmButtonText: "OK",
                    })
                })
            }
		}
	}
</script>
