<template>
	<div class="auth-wrapper auth-v2">
		<div class="auth-inner">
			<!-- brand logo -->
			<!-- <span class="brand-logo d-flex align-center">
				<v-img :src="appLogo" max-height="50px" max-width="50px" alt="logo" contain class="me-3"></v-img>

				<h2 class="primary--text">
					{{ appName }}
				</h2>
			</span> -->
			<!--/ brand logo -->

			<v-row class="auth-row ma-0">
				<v-col lg="8" class="d-none d-lg-block position-relative overflow-hidden pa-0">
					<div class="auth-illustrator-wrapper">
						<!-- triangle bg -->
						<!-- <img height="362" class="auth-mask-bg" :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)" /> -->

						<!-- tree -->
						<!-- <v-img height="226" width="300" class="auth-tree" src="@/assets/images/misc/tree-4.png"></v-img> -->

						<!-- 3d character -->
						<div class="d-flex align-center h-full pa-16 pe-0">
							<v-img contain max-width="100%" max-height="700" class="auth-3d-group"
								:src="require(`@/assets/images/svg/logo.svg`)"></v-img>
						</div>
					</div>
				</v-col>
				<RegisterForm v-if="registerMode" @changeMode="handleChangeMode"></RegisterForm>
				<LoginForm v-else @changeMode="handleChangeMode"></LoginForm>
			</v-row>
		</div>
	</div>
</template>

<script>
import themeConfig from '@themeConfig'

import LoginForm from '@/components/Login/LoginForm.vue'
import RegisterForm from '@/components/Login/RegisterForm.vue'

export default {
	components: {
		LoginForm,
		RegisterForm,
	},
	setup() {
		return {
			// themeConfig
			appName: themeConfig.app.name,
			appLogo: themeConfig.app.logo,
			isDark: themeConfig.app.isDark
		}
	},
	created() {
		this.isDark = false
	},
	data() {
		return {
			registerMode: false
		}
	},
	methods: {
		handleChangeMode() {
			this.registerMode = !this.registerMode
		}
	}
}
</script>
